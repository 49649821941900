<div class='modal-card' [id]='id'>
  <section class='header' *ngIf='title || subTitle'>
    <h2 *ngIf='title'>{{title}}</h2>
    <p p size='p2' color='secondary' *ngIf='subTitle'>{{subTitle}}</p>
  </section>

  <section class='body'>
    <ng-content select='[body]'></ng-content>
  </section>


  <section class='actions'>
    <ng-content select='[actions]'></ng-content>
  </section>
</div>

<div insideClick (onInsideClick)=close() class='background'></div>


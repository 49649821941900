import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';


@Component({
  selector: 'certificate-versions-table',
  templateUrl: './versions-table.component.html',
  styleUrls: ['./versions-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VersionsTableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input() rows: any;
}

import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
// import { MatTableModule } from '@angular/material/table';
// import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CardModule } from '../../shared/components/cards/card/card.module';
import { ComponentModule } from '../../shared/components/component.module';
import { OwnerCertificateComponent } from './owner-certificate/owner-certificate.component';
import { PublicCertificateComponent } from './public-certificate/public-certificate.component';
import { UnicodeVerificationComponent } from './verification/unicode/unicode-verification.component';
import { QrCodeVerificationComponent } from './verification/qr-code/qr-code-verification.component';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { ReactiveFormsModule } from '@angular/forms';
// import { MatInputModule } from '@angular/material/input';
// import { MatButtonModule } from '@angular/material/button';
// import { MatIconModule } from '@angular/material/icon';
import { CertificateComponent } from './certificate/certificate.component';
import { VersionsTableComponent } from './certificate/versions-table/versions-table.component';
import { VerificationListComponent } from './verification/verification-list/verification-list.component';
import { VerifyStatusComponent } from './verification/verify-status/verify-status.component';



@NgModule({
  declarations: [
    PublicCertificateComponent,
    OwnerCertificateComponent,
    UnicodeVerificationComponent,
    QrCodeVerificationComponent,
    CertificateComponent,
    VersionsTableComponent,
    VerificationListComponent,
    VerifyStatusComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    // MatTableModule,
    // MatFormFieldModule,
    // ReactiveFormsModule,
    // MatInputModule,
    // MatButtonModule,
    // MatIconModule,
    ClipboardModule,
    // MatTooltipModule,
    CardModule,
    ComponentModule,
    NgxDatatableModule,
  ],
})
export class CertificatesModule { }

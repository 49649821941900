import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Input() public title: string | undefined;
  @Input() public subTitle: string | undefined;
  @Input() public id: string = 'app-popup-id';

  // @Output() closeEvent = new EventEmitter<true>()

  // @ViewChild(this.id)

  constructor(
    private elementRef: ElementRef,
  ) {
    this.element = document.getElementById(this.id!);
  }

  public element: HTMLElement | null;
  ngOnInit(): void {
    // this.element = document.getElementById(this.id!);
  }

  open() {
    const elem = document.getElementById(this.id!);

    if (elem) {
      elem.style.display = 'flex';
    }

  }

  close() {
    const elem = document.getElementById(this.id!);

    if (elem) {
      elem.style.display = 'none';
    }
  }
}

import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes,
} from '@angular/router';
import { PublicCertificateComponent } from './pages/certificates/public-certificate/public-certificate.component';
import {
  NotFoundPageComponent,
  UnauthorizedPageComponent,
} from './shared/components';
import { AuthComponent } from './auth/auth.component';
import {
  navigation,
  url,
} from './shared/url';


const routes: Routes = [
  {
    path: '',
    redirectTo: `/${url.models.route}`,
    pathMatch: 'full',
    data: { menuItem: navigation[0].name}
  },
  {
    path: '',
    loadChildren: () => import('src/app/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: url.certificates.sharedCertificate,
    component: PublicCertificateComponent,
    data: { shared: true },
  },
  {
    path: url.auth.route,
    component: AuthComponent,
  },
  {
    path: url.auth.unauthorized,
    component: UnauthorizedPageComponent,
  },
  {
    path: url.notFound.route,
    component: NotFoundPageComponent,
  },
  {
    path: '**',
    component: NotFoundPageComponent,
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

<div class='icon-card'>
  <ng-content select='[leftIcon]'></ng-content>
</div>


<div class='name' [style]='getMinWidth()'>
  <div>
    <h3>{{title}}</h3>
  </div>
  <div>
    <p p size='p2' color='secondary'>{{subTitle}}</p>
  </div>
</div>

<ng-content select='[actionIcon]'></ng-content>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { stubPicture } from '../../../shared/utils';
import { IGetPublicCertificate } from '../../../shared/store/state/certificates/public-certificate';
import { CertificateTypes } from '../certificate-type';
import { OwnerCertificateService } from './owner-certificate.service';

@Component({
  selector: 'owner-certificate',
  templateUrl: './owner-certificate.component.html',
  styleUrls: ['./owner-certificate.component.scss']
})
export class OwnerCertificateComponent implements OnInit {
  certificateType = CertificateTypes.Owner;
  certificate$: Subscription = {} as any;
  certificate: IGetPublicCertificate | undefined = undefined;

  public modelImage: string | undefined;

  constructor(
    private publicCertificateService: OwnerCertificateService,
    private route: ActivatedRoute,
  ) {
  }


  ngOnInit(): void {
    const modelId = this.route.snapshot.paramMap.get('id');
    const versionHash = this.route.snapshot.paramMap.get('versionHash');
    this.getOwnerCertificate(modelId, versionHash);
  }



  isProductionCertificate = true;
  getOwnerCertificate(
    modelId: string | null,
    versionHash: string | null,
  ) {
    if (!modelId || !+modelId) {
      console.error(`Public certificate page. id was not recognized or null. Details: ${modelId}`);
      return;
    }

    if (!versionHash) {
      console.error(`Public certificate page. versionHash was not recognized or null. Details: ${versionHash}`);
      return;
    }

    this.certificate$ = this.publicCertificateService.fetchCertificate(+modelId, versionHash)
      .subscribe(res => {
        this.certificate = res;
        this.isProductionCertificate = res.isProductionCertificate;
        this.modelImage = res?.image?.url || stubPicture;
      })
  }
}

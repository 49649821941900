import {
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'radio',
  template: `
    <input type="radio" [id]="id" [disabled]="disabled" [checked]="checked">
    <label [for]="id">
      <ng-content></ng-content>
    </label>
  `,
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  @Input() id: string | undefined;
  @Input() disabled: string | undefined;
  @Input() checked: string | undefined;

  constructor(
    private ref: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.ref.nativeElement.setAttribute('type', 'radio')
  }

}

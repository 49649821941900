import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

type sizes = 'p1' | 'p2' | 'p3' | 'p4' | 'l1' | 'l2' | 'l3' | 'l4';
type colors = 'primary' | 'secondary' | 'accent' | 'opposite';

@Component({
  selector: '[p]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./paragraph.component.scss']
})
export class ParagraphComponent implements OnInit {


  constructor(
  ) { }

  ngOnInit(): void {
  }


  @HostBinding('class') get fontColorAndSize(): string {
    return '' + this.getFontColor() + ' ' + this.getSize();
  }


  getFontColor(): string {
    switch (this.color) {
      case 'primary': return 'primary-p';
      case 'secondary': return 'secondary-p';
      case 'accent': return 'accent-p';
      default: return '';
    }
  }


  getSize(): sizes | '' {
    switch (this.size) {
      case 'p1': return 'p1';
      case 'p2': return 'p2';
      case 'p3': return 'p3';
      case 'p4': return 'p4';
      case 'l1': return 'l1';
      case 'l2': return 'l2';
      case 'l3': return 'l3';
      case 'l4': return 'l4';
      default: return 'p1';
    }
  }

  @Input() size: sizes | undefined;
  @Input() color: colors | undefined;
}

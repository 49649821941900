import {
  Component,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';


type sizes = 'large' | 'small';

@Component({
  selector: '[app-input]',
  template: `
<!--    <input>-->
  `,
  styleUrls: ['./input.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements OnInit {
  @HostBinding('class') get class() {
    switch (this.size) {
      case 'large': return 'input-large';
      case 'small': return 'input-small';
      default: return 'input-large';
    }
  }
  @HostBinding('class.validation-error') get hasErrorClass() {
    if (this.control?.invalid && (this.control.touched || this.control.dirty)) {
      return 'validation-error';
    }
    return '';
  }

  @Input() size: sizes | undefined;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() hint?: string;

  @Input() hasError: boolean = false;

  @Input() control?: AbstractControl;
  @Input() controlName?: string;


  constructor() { }

  ngOnInit(): void {
  }

}

import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Route,
  Router,
} from '@angular/router';
import { environment } from '../../../../environments/environment';
import { url } from '../../../shared/url';
import { stubPicture } from '../../../shared/utils';
import { IGetPublicCertificate } from '../../../shared/store/state/certificates/public-certificate';
import { CertificateTypes } from '../certificate-type';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.scss', 'certificate.component.print.scss']
})
export class CertificateComponent implements OnInit {

  certificateTypes: { [key in CertificateTypes]: CertificateTypes} = {
    [CertificateTypes.Public]: CertificateTypes.Public,
    [CertificateTypes.Owner]: CertificateTypes.Owner,
  }
  @Input() isProductionCertificate = true;
  @Input() certificateType: CertificateTypes | undefined;
  @Input() certificate: IGetPublicCertificate | any;

  @Input() modelImage: string | undefined | null = stubPicture;
  public versionHistoryColumns: string[] = ['version', 'timestamp', 'editor', 'companyName', 'filename'];

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  public verifyUrl = `${environment.url}/certificates/verification/unicode`;

  public getImage() {
    return `background-image: url("${this.certificate!.image.url}");`
  }


  public print() {
    window.print();
  }

  trackedVersions: {
    count: number,
    fromDate: string,
    toDate: string,
  } | undefined = {
    count: 25,
    fromDate: '2021-11-19T11:41:34.500Z',
    toDate: '2021-11-19T11:41:34.500Z',
  };
  public columnNames: string[] = ['Version', 'Timestamp', 'Employee Name', 'Company Name', 'Filename'];


  public goToVerificationPage() {
    this.router.navigate([url.certificates.verificationCertificateByUnicode])
  }
}

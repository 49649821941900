import {
  Directive,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';


@Directive({
  selector: '[insideClick]'
})
export class ClickOutsideDirective {
  @Output() onInsideClick = new EventEmitter<boolean>()

  @HostListener('click', ['$event.target']) onClick(event: any) {
    this.onInsideClick.emit(true);
    console.log('by background', event);
  }

  // @HostListener('document:click', ['$event.target']) onDocClick(event: any) {
  //   this.onClickedOutside.emit(true);
  //   console.log('document:click by popup', event);
  // }
}

import { IconNames } from './components/icon/icon.component';


const prefix = 'track';

export const url = {
  models: {
    route: 'models',
    getModelBydIdFromModelList: (id: number) => `/models/${id}`,
  },
  model: {
    route: `models/:id`,
    publicCertificate: `models/:id/certificates/public`,
    ownerCertificate: `models/:id/certificates/owner/:versionHash`,
    makeNewTransfer: (id: number) => `/models/${id}/transfers/new`,
    api: {
      getPublicCertShareLink: (modelId: number) => `${prefix}/models/${modelId}/certificates/public/share-link`,
    }
  },
  track: {
    getPublicCertificate: (modelId: number) => `/models/${modelId}/certificates/public`,
    getOwnerCertificate: (modelId: number, versionHash: string) => `/models/${modelId}/certificates/owner/${versionHash}`,
    api: {
      getModels: `${prefix}/models`,
      getModelById: (id: number) => `${prefix}/models/${id}`,
      getPublicCertificate: (modelId: number) => `${prefix}/models/${modelId}/certificates/public`,
      getOwnerCertificate: (modelId: number, versionHash: string) => `${prefix}/models/${modelId}/certificates/owner/${versionHash}`,
      postTransfer: `${prefix}/transfers`,
      postVerifyByUnicode: `${prefix}/certificates/verification/unicode`,
      postVerifyByQrCode: `${prefix}/certificates/verification/qrcode`
    },
  },
  certificates: {
    verificationList: `certificates/verifications`,
    verificationCertificateByQrCode: `certificates/verification/qrcode`,
    verificationCertificateByUnicode: `certificates/verification/unicode`,
    sharedCertificate: 'certificates/public/:identity',
    getSharedLink: (identity: string) => `/certificates/public/${identity}`,
    api: {
      getSharedCertificate: (identity: string) => `${prefix}/certificates/${identity}`,
    }
  },
  auth: {
    route: 'auth',
    unauthorized: 'unauthorized',
    api: {
      refreshCoupleTokens: `${prefix}/auth`,
    }
  },
  notFound: {
    route: 'not-found',
  },
  transfers: {
    route: 'transfers',
    new: {
      route: `models/:id/transfers/new`,
    },
    transferById: (id: number) => `/transfers/${id}`,
    get: 'track/transfers',
    esrPerform: 'track/transfers/esr/perform',
    byId: (id: number) => `track/transfers/${id}`,
  },
  transfer: {
    route: "transfers/:id",
  },
  companies: {
    route: 'companies',
    api: {
      getAvailableCompanyAccounts: 'track/companies/available',
    },
  },
  company: {
    route: 'company/:id',
  },
  employees: {
    route: 'employees',
    get: 'admin/employees',
    create: 'employees',
    update: (id: number) => `companies/${id}/employees/add`,
  },
  employee: {
    route: 'employee/:id',
    get: (id: number) => `admin/employees/${id}`,
    token: 'identity/employees',
  },
};

export interface INavigationItem {
  name: string;
  path: string;
  icon: IconNames;
  isFirstPage: boolean;
}

export const navigation: INavigationItem[] = [
  {
    name: 'Models',
    path: '/' + url.models.route,
    icon: 'cube',
    isFirstPage: true,
  },
  {
    name: 'Verification',
    path: '/' + url.certificates.verificationList,
    icon: 'verify',
    isFirstPage: false,
  },
  {
    name: 'Transfers',
    path: '/' + url.transfers.route,
    icon: 'transfer',
    isFirstPage: false,
  },
];


import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { transferStatuses } from '../../utils';


@Component({
  selector: 'app-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
})

export class TableViewComponent implements OnInit, OnChanges {
  @Input() tableData: any = [];
  @Input() page: string = '';
  @Input() columnsToDisplay: string[] = [];
  dataSource: any = [];
  statuses: any = transferStatuses;

  constructor() {
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit() {
    // this.dataSource = new MatTableDataSource(this.tableData);
  }

  ngOnChanges() {
  }
}


export interface PeriodicElement {
  id: number;
  createdDate: string;
  permissionName: string;
}


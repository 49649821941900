import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IconNames } from '../icon/icon.component';

@Component({
  selector: 'switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent implements OnInit {
  @Input() label?: string;
  @Input() id?: string;

  @Input() checked?: boolean;
  @Output() checkedChange = new EventEmitter<boolean>();

  @Input() uncheckedLabel?: string;
  @Input() uncheckedIcon!: IconNames;

  @Input() checkedLabel?: string;
  @Input() checkedIcon!: IconNames;



  constructor() { }

  ngOnInit(): void {
  }

  changeState(e?: any) {
    // console.log('SwitchButtonComponent', this.checked);
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
    // console.log('changed value', this.checked);
    // e.stopPropagation();
    // return this.checked;
  }
}

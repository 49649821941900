import {
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { IconNames } from '../icon/icon.component';

type sizes = 'large' | 'medium' | 'small';


@Component({
  selector: '',
  template: '',
})
export class ButtonBase {
  @Input() icon?: IconNames;

  @HostBinding('class') get class() {
    switch (this.size) {
      case 'large': return 'button-large';
      case 'medium': return 'button-medium';
      case 'small': return 'button-small';
      default: return 'button-large';
    }
  }

  @Input() size: sizes | undefined;
}

import { Injectable } from '@angular/core';

import {
  HttpClient,
  HttpRequest,
} from '@angular/common/http';
import { url } from '../../url';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public static AUTH_HEADER = 'Authorization';
  public static REFRESH_TOKEN_HEADER = 'refreshToken';

  constructor(
    private http: HttpClient,
  ) {}

  public static isRequestToRefreshToken(req: HttpRequest<any>): boolean {
    const header = req.headers.get(AuthService.REFRESH_TOKEN_HEADER);
    return header === '1';
  }

  public static AddPrefixToToken(token: string) {
    return `Bearer ${token}`;
  }

  refreshToken(token: string) {
    const preparedToken = AuthService.AddPrefixToToken(token);

    return this.http.post<{ accessToken: string, refreshToken: string }>(url.auth.api.refreshCoupleTokens, { refreshToken: preparedToken }, {headers: {
        Authorization: preparedToken,
        [AuthService.REFRESH_TOKEN_HEADER]: '1',
      }})
  }
}

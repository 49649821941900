import {
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  DEFAULT_MESSAGES,
  ValidatorTypes,
} from '../../../validators/validator.types';

@Component({
  selector: 'app-input-error',
  template: `
    <ng-container *ngFor='let validator of validatorList'>
      <p p size='p4' *ngIf='controlErrors?.[validator.type]'>{{validator.message}}</p>
    </ng-container>
  `,
  styleUrls: ['./input-error.component.scss']
})
export class InputErrorComponent implements OnInit {
  @Input() errorMessage?: string;
  @Input() type?: ValidatorTypes;
  @Input() controlErrors!: ValidationErrors | null;
  @Input() validatorFn!: any

  constructor() { }

  validatorList: any = [];
  ngOnInit(): void {
    const controlValidators = this.validatorFn && this.validatorFn('');
    if (controlValidators) {
      for (let validator in controlValidators) {
        this.validatorList.push({type: validator, message: DEFAULT_MESSAGES[(validator as ValidatorTypes)] || ''});
      }
    }

    if (!this.errorMessage && this.type) {
      this.errorMessage = DEFAULT_MESSAGES[this.type];
    }
  }
}

import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { IconNames } from '../icon/icon.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input() type!: 'warning' | 'error';
  @Input() title?: string;
  @Input() message!: string;
  @Input() icon?: IconNames;

  @Input() showCloseButton: boolean = false;

  constructor(
    private render: Renderer2,
    private element: ElementRef,
  ) { }

  ngOnInit(): void {
    switch (this.type) {
      case 'warning':
        this.render.addClass(this.element.nativeElement, 'warning');
        break;
      case 'error':
        this.render.addClass(this.element.nativeElement, 'error');
        break;
    }
  }


  public getIconByAlertType(): IconNames {
    switch (this.type) {
      case 'error': return 'error-filled';
      case 'warning': return 'warning-filled';
    }
  }


  public close() {
    this.render.setStyle(this.element.nativeElement, 'display', 'none');
  }
}

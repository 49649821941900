export enum ValidatorTypes {
  Required = 'required',
  Max = 'max',
  Min = 'min',
  Email = 'email',
  Phone = 'phone',
}

export const DEFAULT_MESSAGES: {[key in ValidatorTypes]: string} = {
  [ValidatorTypes.Email]: 'Email is incorrect',
  [ValidatorTypes.Required]: 'Fill out the field',
  [ValidatorTypes.Max]: 'Value exceeded',
  [ValidatorTypes.Min]: 'Value should be more',
  [ValidatorTypes.Phone]: 'Field should has phone number',
}

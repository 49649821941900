import {
  Component,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { SoftwareNames } from '../shared/store/software.names';
import { url } from '../shared/url';
import {
  LocalStorage,
  LocalStorageItemNames,
} from '../shared/store/local.storage';
import { IAppState } from '../shared/store/state/app.state';
import { UpdateTokenBrowserState } from '../shared/store/token-browser/token-browser.actions';
import { StyleService } from '../style/style.service';


@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAppState>,
    private _styleService: StyleService,
  ) {
  }

  ngOnInit(): void {
    this.saveToken();

    const softwareName: string | null = this.route.snapshot.queryParamMap.get('softwareName');
    if (softwareName != null && Object.values(SoftwareNames).includes(softwareName as SoftwareNames)) {
      console.log('Setting up software name on starting. Theme: ' + softwareName)
      this._styleService.softwareName = softwareName as SoftwareNames;
    }
  }

  saveToken() {
    const encodedAccessToken = this.route.snapshot.queryParams.accessToken;
    const encodedRefreshToken = this.route.snapshot.queryParams.refreshToken;
    if (!encodedAccessToken || !encodedRefreshToken) {
      this.router.navigateByUrl(url.auth.unauthorized);
      this.store.dispatch(new UpdateTokenBrowserState({ hasAccessToken: false}));
      return;
    }

    const decodedAccessToken = atob(this.route.snapshot.queryParams.accessToken);
    const decodedRefreshToken = atob(this.route.snapshot.queryParams.refreshToken);
    LocalStorage.setItem(LocalStorageItemNames.accessToken, decodedAccessToken);
    LocalStorage.setItem(LocalStorageItemNames.refreshToken, decodedRefreshToken);

    this.store.dispatch(new UpdateTokenBrowserState({ hasAccessToken: true}));
    this.router.navigateByUrl(url.models.route);
  }
}

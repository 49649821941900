import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-qr-code-verification',
  templateUrl: './qr-code-verification.component.html',
  styleUrls: ['./qr-code-verification.component.scss']
})
export class QrCodeVerificationComponent implements OnInit {
  qrCodeVerificationForm: FormGroup = {} as any;
  verified: boolean | null = null;
  demoCount: number = 1;

  constructor(private _formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.qrCodeVerificationForm = this._formBuilder.group({
      'ownerCode': [null, [Validators.required]],
      'savedTime': [null, [Validators.required]],
    });
  }

  onSubmit(data: string) {
    this.demoCount += 1
    this.verified = this.demoCount % 2 === 0
    setTimeout(() => {
      this.verified = null
    }, 2000)
  }

}

import { HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
  Provider,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { CertificatesModule } from './pages/certificates/certificates.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  AUTH_INTERCEPTOR,
} from './shared/interceptors/auth/auth.interceptor';
import { APP_REDUCERS } from './shared/store/state/app.state';
// import { NotificationComponent } from './notification/notification.component';
import {
  BASE_URL_UPDATE_PROVIDER,
} from './shared/interceptors/baseUrl.interceptor';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { TableViewComponent } from './shared/components/table-view/table-view.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NotFoundPageComponent } from './shared/components';
import { UnauthorizedPageComponent } from './shared/components';
import { ForbiddenPageComponent } from './shared/components';
import { AuthComponent } from './auth/auth.component';
// import { CreateEsrComponent, EsrDataComponent } from './shared/components/create-esr/create-esr.component';



const interceptors: Provider[] = [
  BASE_URL_UPDATE_PROVIDER,
  AUTH_INTERCEPTOR,
];

@NgModule({
  declarations: [
    AppComponent,
    // NotificationComponent,
    TableViewComponent,
    NotFoundPageComponent,
    UnauthorizedPageComponent,
    ForbiddenPageComponent,
    AuthComponent,
    // EsrDataComponent,
    // CreateEsrComponent,
  ],
  imports: [
    BrowserModule,
    CertificatesModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // MatSidenavModule,
    // MatButtonModule,
    // MatListModule,
    // MatIconModule,
    // MatLineModule,
    // MatToolbarModule,
    // MatSnackBarModule,
    // MatCardModule,
    // MatDialogModule,
    // MatStepperModule,
    // MatTableModule,
    // MatFormFieldModule,
    // MatAutocompleteModule,
    // MatInputModule,
    // MatSelectModule,
    ReactiveFormsModule,
    StoreModule.forRoot(APP_REDUCERS),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [interceptors],
  bootstrap: [AppComponent],
  exports: [
    // MatSidenavModule,
    // MatButtonModule,
    // MatListModule,
    // MatIconModule,
    // MatLineModule,
    // MatToolbarModule,
    // MatSnackBarModule,
    // MatCardModule,
    // MatDialogModule,
    // MatStepperModule,
    // MatTableModule,
    // MatFormFieldModule,
    // MatInputModule,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
  ]
})
export class AppModule {
}

export enum LocalStorageItemNames {
  accessToken = 'accessToken',
  refreshToken = 'refreshToken',
  theme = 'theme',
  softwareName = 'softwareName'
}

export class LocalStorage {
  public static saveTokens(
    accessToken: string,
    refreshToken: string,
  ) {
    LocalStorage.removeItem(LocalStorageItemNames.accessToken);
    LocalStorage.setItem(LocalStorageItemNames.accessToken, accessToken);

    LocalStorage.removeItem(LocalStorageItemNames.refreshToken);
    LocalStorage.setItem(LocalStorageItemNames.refreshToken, refreshToken);
  }


  public static removeTokens() {
    LocalStorage.removeItem(LocalStorageItemNames.accessToken);
    LocalStorage.removeItem(LocalStorageItemNames.refreshToken);
  }


  public static getAccessToken() {
    return LocalStorage.getItem(LocalStorageItemNames.accessToken);
  }


  public static getRefreshToken() {
    return LocalStorage.getItem(LocalStorageItemNames.refreshToken);
  }


  public static removeItem(name: LocalStorageItemNames) {
    localStorage.removeItem(name);
  }

  public static setItem(name: LocalStorageItemNames, value: string) {
    localStorage.setItem(name, value);
  }

  public static getItem(name: LocalStorageItemNames): string | null {
    return localStorage.getItem(name);
  }
}

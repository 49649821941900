import { Action } from '@ngrx/store';
import { ITokenBrowserState } from './token-browser.state';


export enum TokenBrowserActions {
  HasAccessToken = '[Token Browser Component] Get Access Token',
  UpdateTokenBrowser = '[Token Browser Component] Update Token Browser',
}

export type TTokenBrowserActions = GetAccessTokenBrowser | UpdateTokenBrowserState;

export class GetAccessTokenBrowser implements Action {
  public readonly type = TokenBrowserActions.HasAccessToken;
}

export class UpdateTokenBrowserState implements Action {
  public readonly type = TokenBrowserActions.UpdateTokenBrowser;
  constructor(
    public payload: ITokenBrowserState,
  ) {
  }
}

import {
  Component,
  Input,
  OnInit,
} from '@angular/core';


type colors = 'primary' | 'secondary' | 'accent' | 'opposite';

export type logos = 'blender-dark-logo' | 'blender-light-logo' | 'inventor-light-logo' | 'inventor-dark-logo';
export type verifyIcons = 'verify-success' | 'verify-success-1' | 'verify-warn' | 'verify-failed' | 'green-check';
export type IconNames =
  'cube'
  | 'share'
  | 'code-square'
  | 'verify'
  | 'transfer'
  | 'arrow-right'
  | 'emptyDoc'
  | 'certificate-cube'
  | 'close'
  | 'user'
  | 'error-filled'
  | 'warning-filled'
  | 'clock-circle'
  | 'light'
  | 'moon'
  | verifyIcons
  | logos;


@Component({
  selector: 'app-icon',
  // template: `<object [id]='icon' [data]='path | safe' [width]='width' [height]='height'></object>`,
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
})
export class IconComponent implements OnInit {
  @Input() icon!: IconNames;
  @Input() width?: number;
  @Input() height?: number;
  @Input() size?: number = 24;
  @Input() fill?: string;
  @Input() class?: string;

  @Input() color?: colors;
  @Input() hoverColor?: colors;

  path!: string;


  constructor() {
  }


  ngOnInit(): void {
    if (!this.width || !this.height) {
      this.width = this.size;
      this.height = this.size;
    }

    this.path = this.iconsPaths[this.icon];
    this.path = this.path + `#${this.icon}`;
  }


  iconsPaths: { [key in IconNames]: string } = {
    'verify-success': `assets/icons/verify/verify.svg`,
    'verify-success-1': `assets/icons/verify/verify-1.svg`,
    'verify-warn': `assets/icons/verify/wait.svg`,
    'verify-failed': `assets/icons/verify/not.svg`,
    'green-check': `assets/icons/verify/green-check.svg`,
    'code-square': `assets/icons/code-square.svg`,
    'arrow-right': `assets/icons/arrow-right.svg`,
    'cube': `assets/icons/cube.svg`,
    'share': `assets/icons/share.svg`,
    'verify': `assets/icons/verify.svg`,
    'transfer': `assets/icons/transfer.svg`,
    'emptyDoc': `assets/icons/empty-doc.svg`,
    'certificate-cube': `assets/icons/certificate-cube.svg`,
    'close': `assets/icons/close.svg`,
    'error-filled': `assets/icons/error-filled.svg`,
    'warning-filled': `assets/icons/warning-filled.svg`,
    'clock-circle': `assets/icons/clock-circle.svg`,
    'user': `assets/icons/user.svg`,
    'light': `assets/icons/light.svg`,
    'moon': `assets/icons/moon.svg`,
    'blender-dark-logo' : `assets/icons/logos/blender-dark-logo.svg`,
    'blender-light-logo' : `assets/icons/logos/blender-light-logo.svg`,
    'inventor-dark-logo' : `assets/icons/logos/inventor-dark-logo.svg`,
    'inventor-light-logo' : `assets/icons/logos/inventor-light-logo.svg`,
  };


  getColor(): string {
    switch (this.color) {
      case 'primary':
        return 'primary';
      case 'secondary':
        return 'secondary';
      case 'accent':
        return 'accent';
      case 'opposite':
        return 'accent';
      default:
        return '';
    }
  }


  getHoverColor(): string {
    switch (this.hoverColor) {
      case 'primary':
        return 'primary-p';
      case 'secondary':
        return 'secondary-p';
      case 'accent':
        return 'accent-p';
      case 'opposite':
        return 'accent-p';
      default:
        return '';
    }
  }
}

import {
  Injectable,
  Provider,
} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


/**
 * Interceptor to inject base url to request url
 */
@Injectable()
export class BaseUrlUpdateInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const updatedUrl = req.clone({ url: `${environment.apiUrl}/${req.url}` });
    return next.handle(updatedUrl);
  }
}


export const BASE_URL_UPDATE_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: BaseUrlUpdateInterceptor,
  multi: true,
};

export const regexEmployeePermissionName = /^[a-z1-5]{2,11}([a-z1-5])?$/;
export const regexEosioAccount = /^[a-z1-5]{12,12}([a-z1-5])?$/;
export const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// export const stubPicture = 'assets/img/placeholder.svg';
export const stubPicture = 'assets/img/placeholder-dark.svg';
// export const stubPicture = "https://cdn.motor1.com/images/mgl/xeKg6/s3/2019-mazda-mx-5-miata-first-drive.jpg";

export const transferStatuses: any = {
  "new": "New",
  "approvedByAdmin": "Approved by admin",
  "declinedByAdmin": "Declined by admin",
  "canceledBySender": "Canceled by sender",
  "signedByAdmin": "Signed by admin",
  "performedBySender": "Performed by sender",
};

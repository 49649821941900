<!--<ul *ngIf="pager.pages && pager.pages.length" class="pagination">-->
<!--  <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">-->
<!--    <a (click)="setPage(1)" class="page-link">First</a>-->
<!--  </li>-->
<!--  <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">-->
<!--    <a (click)="setPage(pager.currentPage - 1)" class="page-link">Previous</a>-->
<!--  </li>-->
<!--  <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item number-item">-->
<!--    <a (click)="setPage(page)" class="page-link">{{page}}</a>-->
<!--  </li>-->
<!--  <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">-->
<!--    <a (click)="setPage(pager.currentPage + 1)" class="page-link">Next</a>-->
<!--  </li>-->
<!--  <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">-->
<!--    <a (click)="setPage(pager.totalPages)" class="page-link">Last</a>-->
<!--  </li>-->
<!--</ul>-->


<ul>
  <li *ngIf='items.length > 5'>
    <a>Previous</a>
  </li>

<!--  <li *ngFor='let i of items'>-->
<!--    <p>{{i.number}}</p>-->
<!--  </li>-->

  <li *ngFor='let i of items'>
    <button secondary-button size='medium' [class.selected]='' (click)='changePage'>{{i.number}}</button>
<!--    <p>{{i.number}}</p>-->
  </li>

  <li  *ngIf='items.length > 5'>
    <a>Next</a>
  </li>
</ul>

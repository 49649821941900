import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { url } from '../../../../shared/url';

@Component({
  selector: 'app-verification-list',
  templateUrl: './verification-list.component.html',
  styleUrls: ['./verification-list.component.scss']
})
export class VerificationListComponent implements OnInit {

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  redirectToUniCodePage() {
    return this.router.navigate([url.certificates.verificationCertificateByUnicode]);
  }

  redirectToQRCodePage() {
    return this.router.navigate([url.certificates.verificationCertificateByUnicode]);
  }

}

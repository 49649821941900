import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  switchMap,
  take,
} from 'rxjs/operators';
import { LocalStorage } from '../../store/local.storage';
import { url } from '../../url';
import { AuthService } from './auth.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  constructor(
    private authService: AuthService,
    private route: Router,
  ) {
  }


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let token: string | null;
    let clonedRequest = req;

    if (AuthService.isRequestToRefreshToken(req)) {
      token = LocalStorage.getRefreshToken();
    } else {
      token = LocalStorage.getAccessToken();
    }

    if (token !== null) {
      clonedRequest = this.cloneRequestAndAddAuthHeader(req, token);
    }
    return next.handle(clonedRequest).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handleAuthError(clonedRequest, next, error);
        }
        return throwError(error);
      }),
    );
  }


  private handleAuthError(
    request: HttpRequest<any>,
    next: HttpHandler,
    err: any,
  ): Observable<HttpEvent<any>>  {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = LocalStorage.getRefreshToken();
      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((res) => {
            this.isRefreshing = false;

            LocalStorage.saveTokens(res.accessToken, res.refreshToken);
            this.refreshTokenSubject.next(res.accessToken);

            return next.handle(this.cloneRequestAndAddAuthHeader(request, res.accessToken));
          }),
          catchError((err) => {
            return this.handleRepeatedTokenRefreshing(err);
          }),
        );
      }
    }

    if (request.url.includes(url.auth.api.refreshCoupleTokens) && this.isRefreshing) {
      return this.handleRepeatedTokenRefreshing(err);
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.cloneRequestAndAddAuthHeader(request, token))),
      catchError((err) => {
        return throwError(err);
      }),
    );
  }


  private cloneRequestAndAddAuthHeader(
    request: HttpRequest<any>,
    token: string,
  ): HttpRequest<any>  {
    const preparedToken = AuthService.AddPrefixToToken(token);
    return request.clone({ headers: request.headers.set(AuthService.AUTH_HEADER, preparedToken) });
  }

  private handleRepeatedTokenRefreshing(err: any) {
    this.isRefreshing = false;

    LocalStorage.removeTokens();
    this.route.navigateByUrl(url.auth.unauthorized)

    return throwError(err);
  }
}


export const AUTH_INTERCEPTOR = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  }
];

import {
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  select,
  Store,
} from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  LocalStorage,
  LocalStorageItemNames,
} from '../../store/local.storage';
import { IAppState } from '../../store/state/app.state';
import { UpdateTokenBrowserState } from '../../store/token-browser/token-browser.actions';
import { tokenBrowserSelector } from '../../store/token-browser/token-browser.selector';
import { ITokenBrowserState } from '../../store/token-browser/token-browser.state';
import {
  INavigationItem,
  navigation,
} from '../../url';
import {
  IconNames,
  logos,
} from '../icon/icon.component';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: [
    './side-nav.component.scss',
    './side-nav.print.component.scss',
  ],
})
export class SideNavComponent implements OnInit {
  tokenBrowserState$: Observable<ITokenBrowserState> = this.store.pipe(select(tokenBrowserSelector));

  public isExpanded = true;
  public navlist = navigation;
  public count = 0;


  constructor(
    public store: Store<IAppState>,
    public activatedRoute: ActivatedRoute,
  ) {
  }


  ngOnInit(): void {
    const accessToken = LocalStorage.getItem(LocalStorageItemNames.accessToken);

    this.store.dispatch(new UpdateTokenBrowserState({ hasAccessToken: !!accessToken }));

    this.activatedRoute.firstChild?.data.subscribe({
      next: (data) => {
        if (data.menuItem) {
          this.selectedItem = data.menuItem;
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }


  markDefaultItem(item: INavigationItem) {
    return item.isFirstPage ? 'activated' : '';
  }


  selectedItem: string | undefined;


  public selectMenuItem(item: INavigationItem) {
    return item.name === this.selectedItem;
  }


  public changeRoute(item: INavigationItem) {
    this.selectedItem = item.name;
  }

  public getLogoIcon(): logos {
    const theme = LocalStorage.getItem(LocalStorageItemNames.theme);
    const softwareName = LocalStorage.getItem(LocalStorageItemNames.softwareName);

    // return `${softwareName || 'blender'}-${theme}-logo` as logos;
    return `${softwareName || 'blender'}-${theme}-logo` as logos;
  }
}


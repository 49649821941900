import {
  AfterViewInit,
  Component,
  ContentChild,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import { InputComponent } from '../../input.component';

@Component({
  selector: 'app-input-form',
  template: `
    <app-input-label
      [for]='controlName'
      *ngIf='label'
      [ngClass]='setValidationErrorClass()'
    >{{label}}</app-input-label>
<!--    <input-->
<!--      app-input-->
<!--      placeholder='Enter email'-->
<!--      formControlName='{{controlName}}'-->
<!--      [ngClass]='setValidationErrorClass()'-->
<!--    />-->

    <ng-content></ng-content>
    <div *ngIf='control.invalid && (control.touched || control.dirty)'>
      <app-input-error [controlErrors]='control.errors' [validatorFn]='control.validator'></app-input-error>
    </div>
  `,
  styleUrls: ['./input-form.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class InputFormComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() label?: string;
  @ContentChild(InputComponent) input?: InputComponent;
  @Input() control!: AbstractControl;
  @Input() controlName: any;



  constructor(
    private render: Renderer2,
  ) { }

  ngOnInit() {
  }

  public ngAfterViewInit(): void {
  }


  public ngOnChanges(changes: SimpleChanges): void {

  }


  public setValidationErrorClass() {
    return this.control.invalid && (this.control.touched || this.control.dirty) ? "validation-error" : ""
  }
}

<section>
  <app-icon [icon]='getIconByAlertType()'></app-icon>
</section>

<div>
  <h4 *ngIf='title'>{{title}}</h4>
  <p p size='p4'>{{message}}</p>
</div>

<section>
  <app-icon *ngIf='showCloseButton' icon='close' (click)='close()'></app-icon>
</section>


import { Injectable } from '@angular/core';
import {
  LocalStorage,
  LocalStorageItemNames,
} from '../shared/store/local.storage';
import { SoftwareNames } from '../shared/store/software.names';
import {
  STYLES,
  ThemeOptions,
} from './styles';


@Injectable({ providedIn: 'root'})
export class StyleService {
  private _themeName!: ThemeOptions;
  get themeName() {
    this._themeName = (LocalStorage.getItem(LocalStorageItemNames.theme) as ThemeOptions || ThemeOptions.Light);
    return this._themeName;
  }

  set themeName(value: ThemeOptions) {
    this._themeName = value;
    LocalStorage.setItem(LocalStorageItemNames.theme, value);
  }

  private _softwareName!: SoftwareNames;
  get softwareName() {
    this._softwareName = (LocalStorage.getItem(LocalStorageItemNames.softwareName) as SoftwareNames || SoftwareNames.Blender);
    return this._softwareName;
  }

  set softwareName(value: SoftwareNames) {
    this._softwareName = value;
    LocalStorage.setItem(LocalStorageItemNames.softwareName, value);
  }


  public loadStyles() {
    for (let param in STYLES[this.softwareName][this.themeName]) {
      document.documentElement.style.setProperty(param, (STYLES[this.softwareName][this.themeName])[param]);
    }
  }
}

<app-card>
  <form
    [formGroup]='unicodeVerificationForm'
    (ngSubmit)='onSubmit()'
    *ngIf='verifyStatus === null; else verifiedTemplate'
  >
    <section>
      <div>
        <h2>Verification of model certificate by Unicode</h2>
      </div>
      <input app-input
             size='small'
             placeholder='Enter the unicode'
             [formControlName]='unicodeVerificationFormFields.unicode'
      >

      <app-alert
        type='warning'
        [showCloseButton]='true'
        message='Enter here the complete unicode from the Certificate that represents specific version of digital model'
      ></app-alert>
    </section>

    <section class='actions'>
      <button primary-button
              type='submit'
              [disabled]='unicodeVerificationForm.invalid'
      >VERIFY UNICODE</button>
    </section>
  </form>

  <ng-template #verifiedTemplate>
    <app-verify-status [status]='verifyStatus' (change)='verifyStatus'
      (buttonClicked)='backToVerificationAnotherOne()'></app-verify-status>
  </ng-template>

</app-card>




<!--<h1>Verification of model certificate by Unicode</h1>-->
<!--<div class='content' *ngIf='verified === null; else verifiedTemplate'>-->
<!--  <div class='description'>-->
<!--    <mat-icon aria-hidden='false' aria-label='Example home icon'>info</mat-icon>-->
<!--    Enter here the complete unicode from the Certificate that represents specific version of digital model-->
<!--  </div>-->
<!--  <div class='verification-form'>-->
<!--    <form [formGroup]='unicodeVerificationForm' (ngSubmit)='onSubmit()'>-->
<!--      <mat-form-field appearance='fill'>-->
<!--        <mat-label>Unicode</mat-label>-->
<!--        <input-->
<!--          matInput-->
<!--          [formControlName]='unicodeVerificationFormFields.unicode'-->
<!--        >-->
<!--      </mat-form-field>-->
<!--      <button-->
<!--        mat-raised-button color='accent' type='submit'-->
<!--        class='button'-->
<!--        [disabled]='!unicodeVerificationForm.valid'-->
<!--      >-->
<!--        Verify-->
<!--      </button>-->
<!--    </form>-->
<!--  </div>-->
<!--</div>-->

<!--<ng-template #verifiedTemplate>-->
<!--  <div class='verified' *ngIf='verified'>-->
<!--    <mat-icon class='verified__icon'>verified_user</mat-icon>-->
<!--    <h1>Verified Successfully</h1>-->
<!--    <p>This model is actually saved in our registry. Your Certificate represents the latest version of this-->
<!--       model.</p>-->
<!--  </div>-->
<!--  <div class='not-verified' *ngIf='!verified'>-->
<!--    <mat-icon class='not-verified__icon'>gpp_bad</mat-icon>-->
<!--    <h1>Verification Failed</h1>-->
<!--    <div *ngFor='let error of verificationErrors'>-->
<!--      <p class='not-verified__text'>{{error}}</p>-->
<!--    </div>-->
<!--  </div>-->

<!--  <button-->
<!--    mat-raised-button color='accent' type='submit'-->
<!--    class='button'-->
<!--    (click)='backToVerificationAnotherOne()'-->
<!--  >-->
<!--    Back-->
<!--  </button>-->
<!--</ng-template>-->

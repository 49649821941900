import {
  Component,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StyleService } from './style/style.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss',
    './app.component.print.scss',
  ]
})
export class AppComponent implements OnInit {
  constructor(
    private _styleService: StyleService,
    private _route: ActivatedRoute,
  ) {
  }


  public ngOnInit(): void {
    this._styleService.loadStyles();
  }
}

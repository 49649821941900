<div class="watermark" *ngIf='!isProductionCertificate'>
  <p>TEST CERTIFICATE</p>
</div>

<section class='card header' *ngIf='certificate'>
    <h2>{{certificateType === certificateTypes.owner ? 'Owner certificate' : 'Public certificate'}}</h2>
    <h1>{{certificate.ownership}}</h1>
    <p p size='p2' color='secondary'>This certificate proves ownership for the listed model and its attributed versions. Creatorship is attributed to the company that originally produced this design.</p>
    <p p size='p2' color='secondary'>Only the holder of the account listed above has the rights to this model. This certificate has specifically been designed to be verifiable and it cannot be forged.</p>
</section>

<section class='card model-details' *ngIf='certificate'>
  <div class='version' *ngIf='certificate.image'>
    <div *ngIf='certificateType === certificateTypes.owner' class='image' [style]='getImage()'></div>
    <div *ngIf='certificateType === certificateTypes.public' class='public-image'>
      <app-icon icon='certificate-cube' [size]='280'></app-icon>
    </div>

    <div class='version__description'>
      <div class='row__1'>
        <h3>{{certificate.file.originalName}}</h3>
        <div class='share'>
          <button tertiary-button size='medium' (click)='print()'>PRINT</button>
        </div>
      </div>
      <div class='row__2'>
        <p p size='p2' class='row-title'>Version:</p>
        <h4>{{certificate.file.originalName}}</h4>
      </div>
    </div>
  </div>
  <div class='version-details'>
    <section class='details' *ngIf='certificate'>
      <h3>Model Details</h3>
      <p p size='p2' color='secondary'>You can open the certificate using the QR code</p>
      <table>
        <tr>
          <td class='row-name'><p p size='p1' color='secondary'>Version Filename</p></td>
          <td><p p size='p1' color='primary'>{{certificate.versionHistory[0].filename}}</p></td>
        </tr>
        <tr>
          <td class='row-name'><p p size='p1' color='secondary'>Company Name</p></td>
          <td><p p size='p1' color='primary'>{{certificate.ownership}}</p></td>
        </tr>
        <tr>
          <td class='row-name'><p p size='p1' color='secondary'>Company Account</p></td>
          <td><p p size='p1' color='primary'>{{certificate.versionHistory[0].companyName}}</p></td>
        </tr>
        <tr>
          <td class='row-name'><p p size='p1' color='secondary'>Employee Name</p></td>
          <td><p p size='p1' color='primary'>{{certificate.versionHistory[0].editor}}</p></td>
        </tr>
      </table>
    </section>
  </div>
</section>

<section class='card'>
  <div class='header'>
    <h2>Recent version history</h2>
    <p p size='p2' color='secondary'>Verify the certificate for more details</p>
  </div>

  <certificate-versions-table [rows]='certificate.versionHistory'>

  </certificate-versions-table>

<!--  <div class='footer' *ngIf='trackedVersions'>-->
<!--    <h4>This certificate tracked {{trackedVersions.count}} versions between <p p size='p2' color='secondary'>{{trackedVersions.fromDate}}</p> and <p p size='p2' color='secondary'>{{trackedVersions.toDate}}</p></h4>-->
<!--    <p p size='p2'>These were signed with {{trackedVersions.count}} digital signatures</p>-->
<!--  </div>-->
</section>



<section class='card'>
  <div class='header'>
    <h2>Recent version history</h2>
    <p p size='p2' color='secondary'>Verify the certificate for more details</p>
  </div>

  <div class='verify-certificate'>
    <section>
      <h3>Use the Unicode</h3>
      <p p size='p2' color='secondary'>You need to copy the code and follow the link</p>

      <div class='version-unicode'>
        <p p size='p4' color='accent'>{{certificate.uniqueCode}}</p>
      </div>

      <button primary-button size='large' (click)='goToVerificationPage()'>Go to verification</button>
    </section>
    <section>
      <h3>Use the QR code</h3>
      <p p size='p2' color='secondary'>You need to use the camera of your device</p>
    </section>
  </div>

</section>
















<!--  </app-card-content>-->
<!--</app-card>-->

<!--<div style='z-index: 100;' *ngIf='certificate'>-->
<!--  <div class="header">-->
<!--    <div class="print-mark" *ngIf='certificateType === certificateTypes.public'>-->
<!--      <div class="print-mark-title">PUBLIC CERTIFICATE</div>-->
<!--      <div class="print-mark-subtitle">POWERED BY CADCHAIN</div>-->
<!--    </div>-->
<!--    <p class='title'>-->
<!--      Proof of-->
<!--      <br>Ownership and Creatorship-->
<!--    </p>-->
<!--  </div>-->

<!--  <p class='ownership'>{{certificate.ownership}}</p>-->
<!--  <hr>-->
<!--  <div class='certificate-description bottom narrow'>-->
<!--    This certificate proves ownership for the listed model and its attributed versions. Creatorship is attributed to the company that originally produced this design. Only the holder of the account listed above has the rights to this model. This certificate has specifically been designed to be verifiable and it cannot be forged.-->
<!--  </div>-->

<!--  <div class="center">-->
<!--    <div id="scanme" >-->
<!--      <img *ngIf='modelImage' class="modelimg" src="{{modelImage}}" alt='Model image'/>-->
<!--      &lt;!&ndash;      <img class="qroverlay"height='75' width='75' src="https://www.kaspersky.nl/content/nl-nl/images/repository/isc/2020/9910/a-guide-to-qr-codes-and-how-to-scan-qr-codes-2.png"/>&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->


<!--  <div class="wrap">-->
<!--    <div class='version'>Recent Version History <span style="font-size: 90%; color: grey">(Verify the certificate for more details)</span></div>-->

<!--    <table mat-table [dataSource]='certificate.versionHistory' class='version-history-table'>-->

<!--      <ng-container matColumnDef="version">-->
<!--        <th mat-header-cell class='table-th' *matHeaderCellDef>Version</th>-->
<!--        <td mat-cell *matCellDef="let element"> {{element.version}} </td>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="timestamp">-->
<!--        <th mat-header-cell class='table-th' *matHeaderCellDef>Timestamp</th>-->
<!--        <td mat-cell *matCellDef="let element"> {{element.timestamp}} </td>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="editor" *ngIf='certificateType === certificateTypes.public'>-->
<!--        <th mat-header-cell class='table-th' *matHeaderCellDef>Editor</th>-->
<!--        <td mat-cell *matCellDef="let element"> {{element.editor}} </td>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="editor"  *ngIf='certificateType === certificateTypes.owner'>-->
<!--        <th mat-header-cell class='table-th' *matHeaderCellDef>Editor</th>-->
<!--        <td mat-cell [matTooltip]="element.editorPublicKey" *matCellDef="let element"> {{element.editor}} </td>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="companyName">-->
<!--        <th mat-header-cell class='table-th' *matHeaderCellDef>Company Name</th>-->
<!--        <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>-->
<!--      </ng-container>-->

<!--      <ng-container matColumnDef="filename">-->
<!--        <th mat-header-cell class='table-th' *matHeaderCellDef>Filename</th>-->
<!--        <td mat-cell *matCellDef="let element"> {{element.filename}} </td>-->
<!--      </ng-container>-->

<!--      <tr mat-header-row *matHeaderRowDef="versionHistoryColumns"></tr>-->
<!--      <tr mat-row *matRowDef="let row; columns: versionHistoryColumns;"></tr>-->
<!--    </table>-->
<!--  </div>-->

<!--  <div class='certificate-description bottom'>-->
<!--    This certificate tracked {{certificate.trackInfo.totalVersions}} versions between {{certificate.trackInfo.startDate}} and {{certificate.trackInfo.endDate}}.-->
<!--    <br>-->
<!--    These were signed with 25 digital signatures.-->
<!--  </div>-->

<!--  <div class="footer">-->
<!--    <h2>-->
<!--      To manually verify the certificate visit:-->
<!--      <br>-->
<!--      <a target='_blank' href="{{verifyUrl}}" class='verify-link'>CADChain/verification</a>-->
<!--    </h2>-->
<!--  </div>-->

<!--  <div class='verify-code'>-->
<!--    <span class="secret">-->
<!--      {{certificate.uniqueCode}}-->
<!--      <button mat-icon-button aria-label="copy unicode" [cdkCopyToClipboard]='certificate.uniqueCode'>-->
<!--        <mat-icon class='copy-unicode'>content_copy</mat-icon>-->
<!--      </button>-->
<!--    </span>-->
<!--  </div>-->

<!--  <div class='footer terms-conditions' >-->
<!--    <span>Certificate generated by CADChain - All models are registered and tracked with our Plugin BORIS for Inventor.</span>-->
<!--  </div>-->
<!--</div>-->

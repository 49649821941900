import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { url } from '../../../shared/url';
import { IGetPublicCertificate } from '../../../shared/store/state/certificates/public-certificate';

@Injectable({
  providedIn: 'root'
})
export class OwnerCertificateService {

  constructor(
    private http: HttpClient,
  ) { }

  fetchCertificate(modelId: number,  versionHash: string): Observable<IGetPublicCertificate> {
    return this.http.get<IGetPublicCertificate>(url.track.api.getOwnerCertificate(modelId, versionHash));
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { url } from '../../../../shared/url';

export interface IVerifiedCode {
  ok: boolean;
  errors: string[];
}


@Injectable({
  providedIn: 'root'
})
export class UnicodeVerificationService {

  constructor(private http: HttpClient) {
  }

  verifyUnicode(unicode: string): Observable<IVerifiedCode> {
    return this.http.post<IVerifiedCode>(url.track.api.postVerifyByUnicode, { unicode });
  }
}

import {
  Component,
  Input,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';


@Component({
  selector: 'app-input-label',
  template: `
    <label [for]='for'><ng-content></ng-content></label>
  `,
  styleUrls: ['./input-label.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class InputLabelComponent implements OnInit {
  @Input() for!: string;

  constructor(
    // private el: Ele
  ) { }

  ngOnInit(): void {

  }

}

<app-card>
  <div>
    <h2>Verification</h2>
    <p p size='p2' color='secondary'>You can choose to verify</p>
  </div>

  <app-button-card [minWidth]='545'
                   title='Use the Unicode'
                   subTitle='You need to copy the code and follow the link'
                   (click)='redirectToQRCodePage()'
  >
    <app-icon id='firstIconCard' leftIcon [size]='40' icon='code-square'></app-icon>
    <app-icon actionIcon icon='arrow-right'></app-icon>
  </app-button-card>

<!--  <app-button-card [minWidth]='545'-->
<!--                   title='Use the QR code'-->
<!--                   subTitle='You need to use the camera of your device'-->
<!--                   icon='code'-->
<!--                   (click)='redirectToQRCodePage()'-->
<!--  >-->
<!--    <app-icon actionIcon icon='arrow'></app-icon>-->
<!--  </app-button-card>-->
</app-card>

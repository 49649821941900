import {
  Component,
  OnChanges,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { finalize } from 'rxjs/operators';
import {
  VerifyStatusComponent,
  VerifyStatuses,
} from '../verify-status/verify-status.component';
import { UnicodeVerificationService } from './unicode-verification.service';


@Component({
  selector: 'app-verification',
  templateUrl: './unicode-verification.component.html',
  styleUrls: ['./unicode-verification.component.scss'],
})
export class UnicodeVerificationComponent implements OnInit {
  unicodeVerificationFormFields = {
    unicode: 'unicode',
  }
  unicodeVerificationForm: FormGroup = {} as any;

  constructor(
    private _formBuilder: FormBuilder,
    private unicodeVerificationService: UnicodeVerificationService,
  ) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.unicodeVerificationForm = this._formBuilder.group({
      'unicode': [null, [Validators.required]],
    });
  }

  verificationErrors: string[] = [];
  onSubmit() {
    const unicode = this.unicodeVerificationForm.controls[this.unicodeVerificationFormFields.unicode].value
    this.unicodeVerificationService.verifyUnicode(unicode)
      .subscribe((res) => {
        this.verifyStatus = VerifyStatuses.SUCCESS;


        if (!res.ok && res.errors) {
          this.verificationErrors = res.errors;
        }
      }, error => {
        this.verifyStatus = VerifyStatuses.FAILED;
        if (error.error) {
          this.verificationErrors = [error.error.message];
        }
      });
  }

  public verifyStatus: VerifyStatuses | null = null;

  public backToVerificationAnotherOne(): void {
    this.unicodeVerificationForm.controls[this.unicodeVerificationFormFields.unicode].setValue(null);
    this.verifyStatus = null;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SafePipe } from '../pipes/safe.pipe';
import { ButtonBase } from './buttons/button-base';
import { PrimaryButtonComponent } from './buttons/primary/primary-button.component';
import { SecondaryButtonComponent } from './buttons/secondary/secondary-button.component';
import { TertiaryButtonComponent } from './buttons/tertiary/tertiary-button.component';
import { TextButtonComponent } from './buttons/text/text-button.component';
import { OutlineCardComponent } from './cards/outline-card/outline-card.component';
import { IconComponent } from './icon/icon.component';
import { IconModule } from './icon/icon.module';
import { PaginationComponent } from './paginator/pagination.component';
import { ParagraphComponent } from './paragraph/paragraph.component';
import { ClickOutsideDirective } from './popup/click-outside.directive';
import { PopupComponent } from './popup/popup.component';
import { InputComponent } from './input/input.component';
import { RadioComponent } from './radio/radio.component';
import { SwitchButtonComponent } from './checkbox/switch-button.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { TableComponent } from './table/table.component';
import { TableModule } from './table/table.module';
import { ButtonCardComponent } from './cards/button-card/button-card.component';
import { ProgressComponent } from './progress/progress.component';
import { ProgressStepComponent } from './progress/progress-step/progress-step.component';
import { ProgressHelperDirective } from './progress/progress-helper.directive';
import { InputLabelComponent } from './input/input-label/input-label.component';
import { InputFormComponent } from './input/input-form/input-form/input-form.component';
import { AlertComponent } from './alert/alert.component';
import { InputErrorComponent } from './input/input-error/input-error.component';



@NgModule({
  declarations: [
    OutlineCardComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TertiaryButtonComponent,
    TextButtonComponent,
    ButtonBase,
    PaginationComponent,
    ParagraphComponent,
    PopupComponent,
    ClickOutsideDirective,
    InputComponent,
    RadioComponent,
    SwitchButtonComponent,
    ButtonCardComponent,
    SafePipe,
    SpinnerComponent,
    SideNavComponent,
    IconComponent,
    ProgressComponent,
    ProgressStepComponent,
    ProgressHelperDirective,
    InputLabelComponent,
    InputFormComponent,
    AlertComponent,
    InputErrorComponent,
  ],
  exports: [
    OutlineCardComponent,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    TertiaryButtonComponent,
    TextButtonComponent,
    ButtonBase,
    IconComponent,
    PaginationComponent,
    ParagraphComponent,
    PopupComponent,
    InputComponent,
    RadioComponent,
    // ComponentModule,
    // TableComponent,
    ButtonCardComponent,
    SideNavComponent,
    IconModule,
    ProgressComponent,
    ProgressStepComponent,
    ProgressHelperDirective,
    InputLabelComponent,
    InputFormComponent,
    AlertComponent,
    SwitchButtonComponent,
  ],
  imports: [
    CommonModule,
    // TableModule,
    // MatProgressSpinnerModule,
    RouterModule,
    // ButtonModule,
    IconModule,
    ReactiveFormsModule,
  ],
})
export class ComponentModule { }

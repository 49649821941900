  <ng-content select='[header]'></ng-content>


  <ng-content select='[center]'></ng-content>

  <ng-content></ng-content>

  <ng-content select='[footer]' class='footer'></ng-content>



import { SoftwareNames } from '../shared/store/software.names';


export enum ThemeOptions {
  Dark = 'dark',
  Light = 'light',
}

export type IThemeOption = {
  [softwareNames in SoftwareNames]: {
    [themeOption in ThemeOptions]: {
      [styleName: string]: string,
    }
  }
}

export const STYLES: IThemeOption = {
  blender: {
    dark: {
      '--primary-color': '#957AE1',
      '--hover-background': '#35323F',
      '--opposite-font-color': '#262626',
      '--bright-background': '#262626',

      '--success-color': '#7BCE79',
      '--accent-warning-color': '#935B06',
      '--warning-color': '#F9BC62',
      '--warning-background': '#3E3528',
      '--accent-failed-color': '#F96161',
      '--failed-color': '#F96161',
      '--failed-background': '#3E2828',

      '--alternative-font-color': '#E5E5E5',

      '--body-background': '#1A1A1A',
      '--font-primary-color': '#E5E5E5',
      '--font-secondary-color': '#808080',
      '--font-opposite-color': 'var(--opposite-font-color)',
      '--font-accent-color': 'var(--primary-color)',
      '--font-light-accent-color': 'var(--hover-background)',
      '--border-accent-color': 'var(--primary-color)',
      '--border-light-accent-color': 'var(--hover-background)',
      '--background-accent-color': 'var(--primary-color)',
      '--background-light-accent-color': 'var(--hover-background)',
      '--card-primary-background': '#262626',
      '--card-primary-border': '1px solid var(--primary-color)',
      '--card-secondary-background': '#303030',
      '--card-secondary-border': '1px solid #404040',
      '--table-border-bottom': '1px solid #404040',
      '--primary-button-font-color': 'var(--opposite-font-color)',
      '--primary-button-background': 'var(--primary-color)',
      '--primary-button-hover-shadow': 'var(--hover-shadow)',
      '--primary-button-active-background': 'var(--pressed-background)',
      '--secondary-button-font-color': 'var(--primary-color)',
      '--secondary-button-background': 'var(--bright-background)',
      '--secondary-button-hover-background': 'var(--hover-background)',
      '--secondary-button-active-background': 'var(--hover-shadow)',
      '--secondary-button-disabled-background': 'var(--bright-background)',
      '--tertiary-button-background': '#303030',
      '--input-background': 'var(--bright-background)',
      '--input-border': '1px solid var(--hover-background)',
      '--input-font-color': '#E5E5E5',
      '--input-placeholder-font-color': '#808080',
      '--checkbox-switcher-background': '#303030',
      '--menu-item-activated-color': 'var(--primary-color)',
      '--menu-item-hover-color': '#303030',
      '--verification-success': 'var(--success-color)',
      '--background-warning': 'var(--warning-background)',
      '--verification-warning': 'var(--warning-color)',
      '--color-warning': 'var(--accent-warning-color)',
      '--background-failed': 'var(--failed-background)',
      '--verification-failed': 'var(--failed-color)',
      '--color-failed': 'var(--accent-failed-color)',
      '--watermark-color': '#F9BC62',
    },
    light: {
      '--background': '#F5F4F7',
      '--bright-background': '#FFFFFF',
      '--hover-background': '#E5DEF7',
      '--pressed-background': '#462995',
      '--primary-color': '#5F35D1',
      '--opposite-font-color': '#FFFFFF',
      '--alternative-font-color': '#9E9E9E',
      '--primary-font-color': '#3D3D3D',
      '--hover-shadow': 'rgba(83, 42, 189, 0.3)',
      '--success-color': '#38D135',
      '--accent-warning-color': '#935B06',
      '--warning-color': '#F6A224',
      '--warning-background': '#FEF4E7',
      '--accent-failed-color': '#940505',
      '--failed-color': '#F83C3C',
      '--failed-background': '#FED8D8',
      '--dark-background': '#1A1A1A',

      '--body-background': 'var(--background)',
      '--font-primary-color': 'var(--primary-font-color)',
      '--font-secondary-color': 'var(--alternative-font-color)',
      '--font-opposite-color': 'var(--opposite-font-color)',
      '--font-accent-color': 'var(--primary-color)',
      '--font-light-accent-color': 'var(--hover-background)',
      '--border-accent-color': 'var(--primary-color)',
      '--border-light-accent-color': 'var(--hover-background)',
      '--background-accent-color': 'var(--primary-color)',
      '--background-light-accent-color': 'var(--hover-background)',
      '--card-primary-background': 'var(--bright-background)',
      '--card-primary-border': '1px solid var(--primary-color)',
      '--card-secondary-background': 'var(--background)',
      '--card-secondary-border': '1px solid var(--hover-background)',
      '--table-border-bottom': '1px solid var(--background)',
      '--primary-button-font-color': 'var(--opposite-font-color)',
      '--primary-button-background': 'var(--primary-color)',
      '--primary-button-hover-shadow': 'var(--hover-shadow)',
      '--primary-button-active-background': 'var(--pressed-background)',
      '--secondary-button-font-color': 'var(--primary-color)',
      '--secondary-button-background': 'var(--bright-background)',
      '--secondary-button-hover-background': 'var(--hover-background)',
      '--secondary-button-active-background': 'var(--hover-shadow)',
      '--secondary-button-disabled-background': 'var(--bright-background)',
      '--tertiary-button-background': 'var(--background)',
      '--input-background': 'var(--bright-background)',
      '--input-border': '1px solid var(--hover-background)',
      '--input-font-color': 'var(--primary-font-color)',
      '--input-placeholder-font-color': 'var(--alternative-font-color)',
      '--checkbox-switcher-background': 'var(--background)',
      '--menu-item-activated-color': 'var(--primary-color)',
      '--menu-item-hover-color': 'var(--background)',
      '--verification-success': 'var(--success-color)',
      '--background-warning': 'var(--warning-background)',
      '--verification-warning': 'var(--warning-color)',
      '--color-warning': 'var(--accent-warning-color)',
      '--background-failed': 'var(--failed-background)',
      '--verification-failed': 'var(--failed-color)',
      '--color-failed': 'var(--accent-failed-color)',
      '--watermark-color': 'var(--font-light-accent-color)',
    },
  },
  inventor: {
    dark: {
      '--primary-color': '#F4A767',
      '--hover-background': '#3F3731',
      '--hover-shadow': 'rgba(223, 136, 34, 0.3)',
      '--opposite-font-color': '#262626',
      '--bright-background': '#262626',

      '--success-color': '#7BCE79',
      '--accent-warning-color': '#935B06',
      '--warning-color': '#F9BC62',
      '--warning-background': '#3E3528',
      '--accent-failed-color': '#F96161',
      '--failed-color': '#F96161',
      '--failed-background': '#3E2828',

      '--alternative-font-color': '#E5E5E5',

      '--body-background': '#1A1A1A',
      '--font-primary-color': '#E5E5E5',
      '--font-secondary-color': '#808080',
      '--font-opposite-color': 'var(--opposite-font-color)',
      '--font-accent-color': 'var(--primary-color)',
      '--font-light-accent-color': 'var(--hover-background)',
      '--border-accent-color': 'var(--primary-color)',
      '--border-light-accent-color': 'var(--hover-background)',
      '--background-accent-color': 'var(--primary-color)',
      '--background-light-accent-color': 'var(--hover-background)',
      '--card-primary-background': '#262626',
      '--card-primary-border': '1px solid var(--primary-color)',
      '--card-secondary-background': '#303030',
      '--card-secondary-border': '1px solid #404040',
      '--table-border-bottom': '1px solid #404040',
      '--primary-button-font-color': 'var(--opposite-font-color)',
      '--primary-button-background': 'var(--primary-color)',
      '--primary-button-hover-shadow': 'var(--hover-shadow)',
      '--primary-button-active-background': 'var(--pressed-background)',
      '--secondary-button-font-color': 'var(--primary-color)',
      '--secondary-button-background': 'var(--bright-background)',
      '--secondary-button-hover-background': 'var(--hover-background)',
      '--secondary-button-active-background': 'var(--hover-shadow)',
      '--secondary-button-disabled-background': 'var(--bright-background)',
      '--tertiary-button-background': '#303030',
      '--input-background': 'var(--bright-background)',
      '--input-border': '1px solid var(--hover-background)',
      '--input-font-color': '#E5E5E5',
      '--input-placeholder-font-color': '#808080',
      '--checkbox-switcher-background': '#303030',
      '--menu-item-activated-color': 'var(--primary-color)',
      '--menu-item-hover-color': '#303030',
      '--verification-success': 'var(--success-color)',
      '--background-warning': 'var(--warning-background)',
      '--verification-warning': 'var(--warning-color)',
      '--color-warning': 'var(--accent-warning-color)',
      '--background-failed': 'var(--failed-background)',
      '--verification-failed': 'var(--failed-color)',
      '--color-failed': 'var(--accent-failed-color)',
      '--watermark-color': '#F9BC62',
    },
    light: {
      '--background': '#E5E5E5',
      '--bright-background': '#FFFFFF',
      '--hover-background': '#FCE9D9',
      '--pressed-background': '#E18A05',
      '--primary-color': '#EF8227',
      '--opposite-font-color': '#FFFFFF',
      '--alternative-font-color': '#9E9E9E',
      '--primary-font-color': '#3D3D3D',
      '--hover-shadow': 'rgba(223, 136, 34, 0.3)',
      '--success-color': '#38D135',
      '--accent-warning-color': '#935B06',
      '--warning-color': '#EF8227',
      '--warning-background': '#FEF4E7',
      '--accent-failed-color': '#940505',
      '--failed-color': '#F83C3C',
      '--failed-background': '#FED8D8',
      '--dark-background': '#303030',

      '--body-background': 'var(--background)',
      '--font-primary-color': 'var(--primary-font-color)',
      '--font-secondary-color': 'var(--alternative-font-color)',
      '--font-opposite-color': 'var(--opposite-font-color)',
      '--font-accent-color': 'var(--primary-color)',
      '--font-light-accent-color': 'var(--hover-background)',
      '--border-accent-color': 'var(--primary-color)',
      '--border-light-accent-color': 'var(--hover-background)',
      '--background-accent-color': 'var(--primary-color)',
      '--background-light-accent-color': 'var(--hover-background)',
      '--card-primary-background': 'var(--bright-background)',
      '--card-primary-border': '1px solid var(--primary-color)',
      '--card-secondary-background': 'var(--background)',
      '--card-secondary-border': '1px solid var(--hover-background)',
      '--table-border-bottom': '1px solid var(--background)',
      '--primary-button-font-color': 'var(--opposite-font-color)',
      '--primary-button-background': 'var(--primary-color)',
      '--primary-button-hover-shadow': 'var(--hover-shadow)',
      '--primary-button-active-background': 'var(--pressed-background)',
      '--secondary-button-font-color': 'var(--primary-color)',
      '--secondary-button-background': 'var(--bright-background)',
      '--secondary-button-hover-background': 'var(--hover-background)',
      '--secondary-button-active-background': 'var(--hover-shadow)',
      '--secondary-button-disabled-background': 'var(--bright-background)',
      '--tertiary-button-background': 'var(--background)',
      '--input-background': 'var(--bright-background)',
      '--input-border': '1px solid var(--hover-background)',
      '--input-font-color': 'var(--primary-font-color)',
      '--input-placeholder-font-color': 'var(--alternative-font-color)',
      '--checkbox-switcher-background': 'var(--background)',
      '--menu-item-activated-color': 'var(--primary-color)',
      '--menu-item-hover-color': 'var(--background)',
      '--verification-success': 'var(--success-color)',
      '--background-warning': 'var(--warning-background)',
      '--verification-warning': 'var(--warning-color)',
      '--color-warning': 'var(--accent-warning-color)',
      '--background-failed': 'var(--failed-background)',
      '--verification-failed': 'var(--failed-color)',
      '--color-failed': 'var(--accent-failed-color)',
      '--watermark-color': 'var(--font-light-accent-color)',
    },
  }
}

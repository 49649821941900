import {
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { IconNames } from '../../icon/icon.component';


@Component({
  selector: 'app-button-card',
  templateUrl: './button-card.component.html',
  styleUrls: ['./button-card.component.scss'],
})
export class ButtonCardComponent implements OnInit {
  @Input() title: string | null = null;
  @Input() subTitle: string | null = null;

  @Input() minWidth: number | undefined;

  @Input() actionIconClass: any;

  constructor(
  ) { }

  ngOnInit(): void {

    console.log(this.actionIconClass)
  }

  @Input() actionIcon: IconNames | undefined;

  public getMinWidth() {
    if (this.minWidth) {
      return `min-width: ${this.minWidth}px`;
    }
    return '';
  }
}

<div class='logo'>
  <app-icon [width]='197' [height]='54' [icon]='getLogoIcon()'></app-icon>
</div>

<ul class='navigation' >
  <li class='menu-item'  [class.selected]='selectMenuItem(item)'  [routerLink]="item.path" (click)="changeRoute(item)" *ngFor="let item of navlist" [ngClass]='markDefaultItem(item)'>
  <div id='menu-item-marker' class='hovered'></div>
      <app-icon [size]='24' [icon]='item.icon'></app-icon>
      <p p size='l1'>{{item.name}}</p>
  </li>
</ul>

import { ActionReducerMap } from '@ngrx/store';
import { tokenBrowserReducer } from '../token-browser/token-browser.reducer';
import {
  INITIAL_TOKEN_BROWSER_STATE,
  ITokenBrowserState,
} from '../token-browser/token-browser.state';
import { CompanyInterface } from './company.state';


export interface IAppState {
  tokenBrowser: ITokenBrowserState;
  companies: CompanyInterface[];
  company?: CompanyInterface | null;
}


export const INITIAL_APP_STATE: IAppState = {
  tokenBrowser: INITIAL_TOKEN_BROWSER_STATE,
  companies: [],
  company: null,
};

export const getInitialState = (): IAppState => {
  return INITIAL_APP_STATE;
};


export const APP_REDUCERS: ActionReducerMap<any, any> = {
  tokenBrowser:  tokenBrowserReducer,
}

import {
  Component,
  OnInit,
} from '@angular/core';
import { ButtonBase } from '../button-base';


@Component({
  selector: '[tertiary-button]',
  template: `
    <app-icon *ngIf='icon' [icon]='icon'></app-icon>
    <ng-content></ng-content>
  `,
  styleUrls: ['./tertiary-button.component.scss'],
})
export class TertiaryButtonComponent extends ButtonBase implements OnInit {
  ngOnInit(): void {
  }
}

import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { url } from '../../../shared/url';
import { IGetPublicCertificate } from '../../../shared/store/state/certificates/public-certificate';
import { CertificateTypes } from '../certificate-type';
import { PublicCertificateService } from './public-certificate.service';

@Component({
  selector: 'public-certificate',
  templateUrl: './public-certificate.component.html',
  styleUrls: ['./public-certificate.component.scss']
})
export class PublicCertificateComponent implements OnInit, OnDestroy {
  certificateType = CertificateTypes.Public;
  certificate$: Subscription = {} as any;
  certificate: IGetPublicCertificate | undefined = undefined;

  isProductionCertificate = true;


  versionHistoryColumns: string[] = ['version', 'timestamp', 'editor', 'companyName', 'filename'];

  constructor(
    private publicCertificateService: PublicCertificateService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  private routeData$ = Subscription.EMPTY;
  ngOnInit(): void {
    this.route.data.subscribe({
      next: (data) => {
        if (data.shared) {
          const identity = this.route.snapshot.paramMap.get('identity');
          this.getSharedPublicCertificate(identity)
        } else {
          const modelId = this.route.snapshot.paramMap.get('id');
          this.getPublicCertificate(modelId);
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  }


  public ngOnDestroy() {
    this.routeData$.unsubscribe();
  }


  getPublicCertificate(modelId: string | null) {
    if (!modelId || !+modelId) {
      console.error(`Public certificate page. id was not recognized or null. Details: ${modelId}`);
      return;
    }
    this.certificate$ = this.publicCertificateService.fetchCertificate(+modelId)
      .subscribe(res => {
        this.certificate = res;
        this.isProductionCertificate = res.isProductionCertificate;
      })
  }


  getSharedPublicCertificate(identity: string | null) {
    if (!identity) {
      console.error(`Public certificate page. Identity was not recognized or null. Details: ${identity}`);
      return;
    }
    this.certificate$ = this.publicCertificateService.getSharedCertificate(identity)
      .subscribe({
        next: (res) => {
          this.certificate = res;
          this.isProductionCertificate = res.isProductionCertificate;
        },
        error: (err) => {
          console.error(err);
          this.router.navigate([url.notFound.route]);
        }
      })
  }
}

import {
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { UiHelper } from '../uiHelper';

@Component({
  selector: 'app-progress-step',
  templateUrl: './progress-step.component.html',
  styleUrls: ['./progress-step.component.scss']
})
export class ProgressStepComponent implements OnInit {

  stepIndex!: number;

  @HostBinding('class.activeStep') isActive = false;

  @Input() set activeState(step: any) {
    this.isActive = step === this;
  }

  @Input() isNextStepAllowed: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}

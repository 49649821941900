import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { verifyIcons } from '../../../../shared/components/icon/icon.component';


export enum VerifyStatuses {
  SUCCESS = 1,
  WARNING = 2,
  FAILED = 3,
}


@Component({
  selector: 'app-verify-status',
  templateUrl: './verify-status.component.html',
  styleUrls: ['./verify-status.component.scss']
})
export class VerifyStatusComponent implements OnInit, OnChanges {
  statusInfos: {[key in VerifyStatuses]: { title: string, subTitle: string, icon: verifyIcons, titleClass: string}} = {
    [VerifyStatuses.SUCCESS]: {
      title: 'Verified Successfully',
      subTitle: 'This model is actually saved in our registry. Your Certificate represents the latest version of' +
        ' this model',
      icon: 'verify-success',
      titleClass: 'success',
    },
    [VerifyStatuses.WARNING]: {
      title: 'Warning',
      subTitle: 'Information is outdated',
      icon: 'verify-warn',
      titleClass: 'warning',
    },
    [VerifyStatuses.FAILED]: {
      title: 'Verification Failed',
      subTitle: 'Model was not found by version hash',
      icon: 'verify-failed',
      titleClass: 'failed',
    }

  }
  title: string | null = null;
  subTitle: string | null = null;
  titleClass!: string;

  @Input() status: VerifyStatuses | null = null;

  @Output() buttonClicked = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }


  @Input() icon!: verifyIcons;

  public back() {
    this.buttonClicked.emit(true);
  }


  public ngOnChanges(changes: SimpleChanges): void {
    const currentStatus = changes['status'].currentValue;
    if (!currentStatus) {
      return;
    }

    const statusInfo = this.statusInfos[currentStatus as VerifyStatuses];

    this.title = statusInfo.title;
    this.subTitle = statusInfo.subTitle;
    this.icon = statusInfo.icon;
    this.titleClass = statusInfo.titleClass;
  }
}

import {
  TokenBrowserActions,
  TTokenBrowserActions,
} from './token-browser.actions';
import {
  INITIAL_TOKEN_BROWSER_STATE,
  ITokenBrowserState,
} from './token-browser.state';


export const tokenBrowserReducer = (
  state = INITIAL_TOKEN_BROWSER_STATE,
  action: TTokenBrowserActions,
): ITokenBrowserState => {
  switch (action.type) {
    case TokenBrowserActions.HasAccessToken:
      return { ...state };
    case TokenBrowserActions.UpdateTokenBrowser:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
